/**
 * @file
 * Custom JS behaviour for alpitour_world theme.
 */

// Dimensions.
var big_width = 1441,
  full_width = 1180,
  medium_width = 980,
  small = 500,
  small_start = 460,
  small_end = 640;

(function ($) {
  // global code
  Drupal.behaviors.alpitour_world = {
    attach: function (context, settings) {
      if (typeof addthis !== 'undefined') {
        addthis.init();
        addthis.toolbox('.addthis_toolbox');
      }
    }
  };

})(jQuery);
